<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        sm="12"
        md="7"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-form-group :label="$t('globalSingular.type')" label-for="type">
              <validation-provider
                #default="{ errors }"
                name="type"
              >
                <v-select
                  id="type"
                  v-model="form.isBank"
                  :options="LOV.type"
                  :reduce="field => field.value"
                  label="name"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('apps.masterDataCashbank.singular.code')"
              label-for="code"
            >
              <b-form-input
                id="category-code"
                v-model="form.code"
                :placeholder="$t('apps.masterDataCashbank.placeholder.code')"
                :disabled="actions.isPreview"
              />
            </b-form-group>
            <b-form-group
              :label="$t('apps.masterDataCashbank.singular.name')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="category-name"
                  v-model="form.name"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="!actions.isPreview ? $t('apps.masterDataCashbank.placeholder.name') : null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('globalSingular.account')" label-for="Account">
              <validation-provider
                #default="{ errors }"
                name="Account"
              >
                <v-select
                  id="Account"
                  v-model="form.accountId"
                  :options="LOV.account"
                  :reduce="field => field.id"
                  label="label"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
            
            <b-row v-if="form.isBank">
              <b-col md="4">
                <b-form-group label="Bank" label-for="Bank">
                  <validation-provider
                    #default="{ errors }"
                    name="Bank"
                  >
                    <v-select
                      id="Bank"
                      v-model="form.bankCode"
                      :options="LOV.banks"
                      :reduce="field => field.code"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  :label="$t('apps.masterDataCashbank.placeholder.bankAccountName')"
                  label-for="bankAccountName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="bankAccountName"
                    rules="required"
                  >
                    <b-form-input
                      id="bankAccountName"
                      v-model="form.bankAccountName"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataCashbank.placeholder.bankAccountName') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('apps.masterDataCashbank.placeholder.bankAccountNumber')"
                  label-for="bankAccountNumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="bankAccountNumber"
                    rules="required"
                  >
                    <b-form-input
                      id="bankAccountNumber"
                      v-model="form.bankAccountNumber"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataCashbank.placeholder.bankAccountNumber') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('apps.masterDataCashbank.placeholder.bankOffice')"
                  label-for="bankOffice"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="bankOffice"
                    rules="required"
                  >
                    <b-form-input
                      id="bankOffice"
                      v-model="form.bankOffice"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataCashbank.placeholder.bankOffice') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group :label="$t('globalSingular.office')" label-for="type" v-if="company.plan_id > 2">
              <validation-provider
                #default="{ errors }"
                name="type"
              >
                <v-select
                  id="type"
                  v-model="form.officeId"
                  :options="LOV.offices"
                  :reduce="field => field.id"
                  label="name"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>

            <div class="mt-2" v-show="actions.isPreview || actions.isEditMode">
              <div class="mt-1 d-flex flex-col">
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                />
                <h4 class="mb-0 ml-50 text-decoration-underline">
                  {{ $t('globalSingular.userMapping') }}
                </h4>
              </div>
              <hr>
              <b-row v-show="actions.isEditMode">
                <b-col sm="12" md="8">
                  <b-form-group :label="$t('globalSingular.user')" label-for="user-mapping" v-if="company.plan_id > 2">
                    <validation-provider
                      #default="{ errors }"
                      name="user-mapping"
                    >
                      <v-select
                        id="user-mapping"
                        v-model="formUsers.users"
                        :options="LOV.users"
                        :reduce="field => field.id"
                        multiple
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0]}}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-button block variant="info" class="mt-2 pd-1" @click="handleAddMappingUsers" :disabled="actions.isPreview">
                    {{ $t('globalActions.add') }}
                  </b-button>
                </b-col>
              </b-row>
              
              <b-row>
                <b-col sm="12">
                  <b-list-group>
                    <b-list-group-item class="d-flex align-items-center justify-content-between" v-for="(mapping, index) in LOV.mappingUsers" :key="mapping.id">
                      <div>
                        <span class="mr-1">
                          <!-- <feather-icon
                            icon="UserIcon"
                            size="16"
                          /> -->
                          {{ index + 1 }}
                        </span>
                        <span>{{ mapping.user.user_name }}</span>
                      </div>
                      
                      <div>
                        <b-button size="sm" variant="flat-danger" class="btn-icon" @click="handleDeleteMappingUser(mapping.id)">
                          <feather-icon icon="TrashIcon" size="16" />
                        </b-button>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="text-center" v-if="!LOV.mappingUsers.length">
                      <div>{{ $t('globalAlerts.noUserFound') }}</div>
                      <div>{{ $t('globalAlerts.subTitle.noUserFound') }}</div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </div>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-cashbank-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BForm,
  BListGroup,
  BListGroupItem,
  // BFormTextarea,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import VuexStore from '@/store'

import useFormResource from '@/comp-functions/useFormResource'
import useHttp from '@/comp-functions/useHttp'

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    BListGroup,
    BListGroupItem,
    // BFormTextarea,
    BButton,
    // BFormText,
    // BFormDatalist,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/cashbank', localeContextPath: 'apps.masterDataCashbank.singular.cashbank', redirectPathName: 'apps-master-data-cashbank' })

    const { $post, $delete } = useHttp()

    const company = computed(() => {
      return VuexStore.state.user.currentCompany
    })


    const form = ref({
      code: '',
      name: '',
      isBank: false,
      bankCode: '',
      bankAccountName: '',
      bankAccountNumber: '',
      bankOffice: '',
      minimumBalance: 0,
      accountId: null,
      officeId: null,
      users: []
    })

    const formUsers = ref({
      cashbankId: null,
      users: []
    })

    // LOV (select option)
    const LOV = ref({
      type: [
        { value: true, name: 'Bank' },
        { value: false, name: 'Cash' }
      ],
      banks: [],
      account: [],
      offices: [],
      mappingUsers: [],
      users: []
    })

    const getCompanyUsers = async officeId => {
      LOV.value.users = await get({ url: `value/cashbank-users?office_id=${officeId}` })
    }

    const getUserMapping = async cashbankId => {
      LOV.value.mappingUsers = await get({ url: `master/cashbank-user?cashbank_id=${cashbankId}` })
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data[0].code
      form.value.name = data[0].name
      form.value.isBank = data[0].is_bank
      form.value.bankCode = data[0].bank_code
      form.value.bankAccountName = data[0].bank_account_name
      form.value.bankAccountNumber = data[0].bank_account_number
      form.value.bankOffice = data[0].bank_office
      form.value.minimumBalance = data[0].minimum_balance
      form.value.accountId = data[0].account_id
      form.value.officeId = data[0].office_id
      formUsers.value.cashbankId = data[0].id
      getCompanyUsers(form.value.officeId)
      getUserMapping(formUsers.value.cashbankId)
    }

    // Get bank lists
    const getBank = async () => {
      LOV.value.banks = await get({ url: 'value/banks'})
    }

    // Get account types
    const getAccount = async () => {
      LOV.value.account = await get({ url: 'master/accounts'})
    }

    // Get offices
    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }

    const deleteCashbankUser = (id, callbackSuccess) => {
      $delete({
        url: `master/cashbank-user/${id}`
      })
        .then(() => {
          formUsers.value.users = []
          getUserMapping(formUsers.value.cashbankId)
          getCompanyUsers(form.value.officeId)
          callbackSuccess()
        })
        .catch(e => console.log('e', e))

    }

    const submitCashbankUsers = (callbackSuccess) => {
      $post({
        url: 'master/cashbank-user',
        data: formUsers.value
      })
        .then(() => {
          formUsers.value.users = []
          getUserMapping(formUsers.value.cashbankId)
          getCompanyUsers(form.value.officeId)
          callbackSuccess()
        })
        .catch(e => console.log('e', e))
    }

    onMounted(() => {
      // console.log('company => ', company.value)
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
        // getCompanyUsers(form.value.officeId)
        // getUserMapping(formUsers.value.cashbankId)
      }
      getAccount()
      getBank()
      if (company.value.plan_id > 2) {
        getOffice()
      }
    })

    return {
      formUsers,
      deleteCashbankUser,
      submitCashbankUsers,
      LOV,
      company,
      required,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    },
    handleAddMappingUsers () {
      this.$swal({
        title: this.$t('globalAlerts.confirmAddUserMapping'),
        text: this.$t('globalAlerts.subTitle.confirmAddUserMapping'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value}) => {
          if (value) {
            this.submitCashbankUsers(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${this.$t('feedback.success')} ${this.$t('globalAlerts.successAddUserMapping')}`
                }
              })
            })
          }
        })
    },
    handleDeleteMappingUser (mappingId) {
      this.$swal({
        title: this.$t('globalAlerts.confirmDeleteUserMapping'),
        text: this.$t('globalAlerts.subTitle.confirmDeleteUserMapping'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value}) => {
          if (value) {
            this.deleteCashbankUser(mappingId, () => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${this.$t('feedback.success')} ${this.$t('globalAlerts.successDeleteUserMapping')}`
                }
              })
            })
            // this.delete({
            //   url: `transaction/purchase/invoice/${this.$router.currentRoute.params.id}`
            // }).then(() => {
            //   this.$router.replace({ name: 'apps-transactions-sales-invoice-list' })
            //   this.$toast({
            //     component: ToastificationContent,
            //     position: 'top-right',
            //     props: {
            //       title: 'Success',
            //       icon: 'BellIcon',
            //       variant: 'success',
            //       text: `${this.$t('feedback.success')} ${this.$t('apps.transactions.sales.invoice.singular.deleteInvoice')}`
            //     }
            //   })
            // })
          }
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
